import React from "react";
import {
  Dialog,
  Pane,
  Strong,
  Text,
  PhoneIcon,
  Icon,
  IconButton,
} from "evergreen-ui";
import { connect } from "react-redux";

const ContactSupportDialogContent = (props) => {
  const {
    showContactSupportDialog,
    handleContactSupportDialogVisibility,
    escalationMatrixData,
  } = props;

  const levels = ["level1", "level2", "level3"];

  return (
    <Dialog
      isShown={showContactSupportDialog}
      title={"Support"}
      onCloseComplete={() => handleContactSupportDialogVisibility("close")}
      hasFooter={false}
      width={"42%"}
    >
      {escalationMatrixData ? (
        <React.Fragment>
          {levels.map((level, index) => {
            level = escalationMatrixData["escalationMatrix"][level];

            // Check if the necessary fields are available in the level object
            const hasEmail = level.hasOwnProperty("email");
            const hasWhatsAppNumber = level.hasOwnProperty("whatsAppNumber");
            const hasWebForm = level.hasOwnProperty("webForm");

            return (
              <Pane
                key={index}
                display={"flex"}
                flexDirection={"column"}
                marginBottom={20}
              >
                <Strong size={500}>{`Step ${index + 1}`}</Strong>
                <Text size={300}>{level["message"]}</Text>
                <Pane
                  minHeight={60}
                  display={"flex"}
                  flexDirection={"row"}
                  background={"#E4E7EB"}
                  borderRadius={5}
                  justifyContent={"space-between"}
                  alignItems={"start"}
                  gap={20}
                  padding={10}
                  marginTop={8}
                  border
                >
                  {level["name"] && (
                    <Pane
                      display={"flex"}
                      marginBottom={10}
                      width={"100%"}
                      flexDirection={"column"}
                    >
                      <Strong paddingBottom={5}>{level["name"]}</Strong>
                      <Text size={300} paddingBottom={5} paddingTop={5}>
                        {level["designation"]}
                      </Text>
                    </Pane>
                  )}
                  {level["mobile"] && (
                  <Pane
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"start"}
                    gap={6}
                    width={"100%"}
                    marginBottom={10}
                    justifyContent={"space-between"}
                  >
                    <Strong>Mobile</Strong>
                    <Pane
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"start"}
                    >
                      {hasWhatsAppNumber && (
                        <Pane
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"start"}
                          marginBottom={4}
                        >
                          <img
                            width={"18px"}
                            height={"18px"}
                            src="https://playo-website.gumlet.io/venues-listing/socail/whatsapp.svg?w=50&h=50"
                            alt="WhatsApp icon"
                            style={{ marginRight: "5px" }}
                          />
                          <Strong
                            is="a"
                            target="_blank"
                            href={`https://wa.me/${level["whatsAppNumber"]}`}
                            className={"no-underline"}
                          >
                            {level["whatsAppNumber"]}
                          </Strong>
                        </Pane>
                      )}
                      <Pane
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"start"}
                      >
                        <PhoneIcon size={16} color="success" marginRight={5} />
                        <Strong
                          is="a"
                          href={`tel:${level["mobile"]}`}
                          className={"no-underline"}
                        >
                          {level["mobile"]}
                        </Strong>
                      </Pane>
                    </Pane>
                  </Pane>
                  )}

                  {/* Check if email exists in the level object */}
                  {level["email"] && (
                    <Pane
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"start"}
                      marginBottom={10}
                      gap={6}
                      justifyContent={"space-between"}
                    >
                      <Strong>Email</Strong>

                      <Text
                        is={"a"}
                        size={100}
                        href={`mailto:${level["email"]}`}
                        className={"no-underline"}
                      >
                        {level["email"]}
                      </Text>
                    </Pane>
                  )}

                  {/* Check if webForm exists in the level object */}
                  {hasWebForm && (
                    <Pane
                      display={"flex "}
                      flexDirection={"column"}
                      alignItems={"start"}
                      width={"100%"}
                      gap={6}
                      justifyContent={"space-between"}
                    >
                      <Strong display={"inline"}>Support Ticket</Strong>

                      <Pane
                        alignItems={"center"}
                        background={"#00B562"}
                        borderRadius={"4px"}
                        color={"white"}
                        paddingX={"8px"}
                        height={"32x"}
                      >
                        <Text
                          is="a"
                          color="white"
                          rel="noopener noreferrer"
                          href={level["webForm"]}
                          target="_blank"
                          display={"flex"}
                        >
                          {"Submit"}
                        </Text>
                      </Pane>
                    </Pane>
                  )}
                </Pane>
              </Pane>
            );
          })}
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}

      {/* Display email for the overall escalation matrix */}
      {escalationMatrixData &&
        escalationMatrixData["escalationMatrix"]["email"] && (
          <React.Fragment>
            <Strong size={500}>Email</Strong>
            <Pane
              display={"flex"}
              flexDirection={"column"}
              background={"#E4E7EB"}
              borderRadius={5}
              padding={5}
              marginTop={5}
              border
            >
              <Text size={300}>
                {escalationMatrixData["escalationMatrix"]["email"]["message"]}
              </Text>
              <Pane
                marginTop={10}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Icon
                  icon="envelope"
                  color="success"
                  size={16}
                  marginRight={5}
                />
                <Strong
                  is={"a"}
                  href={`mailto:${escalationMatrixData["escalationMatrix"]["email"]["email"]}`}
                  className={"no-underline"}
                >
                  {escalationMatrixData["escalationMatrix"]["email"]["email"]}
                </Strong>
              </Pane>
            </Pane>
          </React.Fragment>
        )}
    </Dialog>
  );
};

const mapStateToProps = ({ escalationMatrixData }) => ({
  escalationMatrixData,
});

export default connect(mapStateToProps)(ContactSupportDialogContent);
